import React from 'react';
import { useParams } from 'react-router-dom'
import ViewSongComponent from '../components/ViewSongComponent';
import { SongUtil } from '../utils/SongUtil'

export default function ViewSongPage() {
  const songs = JSON.parse(localStorage.getItem("songs"))
  SongUtil.updateUrl(songs)
  const selectedSongIndex = SongUtil.getSongIndex(songs, useParams().songUuid)
  const selectedSong = songs[selectedSongIndex]

  return (
    <ViewSongComponent
      songs={songs}
      selectedSong={selectedSong}
      selectedSongIndex={selectedSongIndex}
      url={"/songs"}
      previousPageUrl={"/"}
    />
  )
}