import axios from 'axios';

const apiUrl = "https://api-taraneem.onrender.com/api/v1/auth"

export const AuthApi = {
  login: async function (username, password) {
    try {
      const response = await axios.post(
        `${apiUrl}/login`,
        {
          username: username,
          password: password
        }
      )
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}