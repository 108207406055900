import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

export default function NavigationBarComponent() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="info">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Link to={"/"}>
              <Button size="large" sx={{color: "white"}}>Taraneem</Button>
            </Link>
          </Typography>
          {(localStorage.getItem("token") != null) ?
            <Link to={`/song-lists`}>
              <Button sx={{color: "white"}}>Go to Song Lists</Button>
            </Link>
            :
            <Link to={`/login`}>
              <Button sx={{color: "white"}}>Login</Button>
            </Link>
          }
        </Toolbar>
      </AppBar>
    </Box>
  );
}