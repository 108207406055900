import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Container, InputAdornment, TextField } from "@mui/material";

export default function SearchComponent({ setSearchTerm }) {
  return (
    <Container >
      <TextField
        type="search"
        size="small"
        placeholder="Search..."
        onChange={event => {
          setSearchTerm(event.target.value)
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </Container>
  )
}