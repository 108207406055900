import { useMemo, useState } from 'react';
import {
  MRT_TableContainer,
  useMaterialReactTable,
} from 'material-react-table';
import { useNavigate } from "react-router-dom"

export default function SongListsDataTableComponent({ songLists }) {
  const navigate = useNavigate()
  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Title',
      }
    ],
    [],
  );

  const [data] = useState(() => songLists);

  const table = useMaterialReactTable({
    autoResetPageIndex: false,
    columns,
    data,
    enableRowOrdering: false,
    enableSorting: false,
    enableRowActions: false,
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        navigate(`/song-lists/${row.original.uuid}`)
      }
    }),
  });

  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <MRT_TableContainer table={table} style={{ width: '90%' }} />
  )

}