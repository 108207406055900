import React from 'react';
import { useState, useEffect } from 'react';
import SongListSongsDataTableComponent from '../components/SongListSongsDataTableComponent';
import LoadingComponent from '../components/LoadingComponent';
import { useParams } from "react-router-dom";
import { SongListApi } from '../apis/SongListApi';
import BackComponent from '../components/BackComponent';
import AutocompleteComponent from '../components/AutocompleteComponent';

export default function ViewSongListPage() {

  const [isLoading, setIsLoading] = useState(true)
  const [songList, setSongList] = useState(null)

  const songListUuid = useParams().songListUuid;

  useEffect(() => {
    SongListApi.get(songListUuid).then(result => {
      setSongList(result)
      setIsLoading(false)
    })
  }, [songListUuid])

  if (isLoading) {
    return (
      <LoadingComponent />
    )
  }

  return (
    <div className="container-fluid text-center">
      <br></br>
      <BackComponent url={`/song-lists`} />
      {songList.name}
      <br></br>
      Created By: {songList.createdBy.firstName}
      <br></br>
      <br></br>
      {
        songList.description != null ?
          <div>
            Description: {songList.description}
            <br></br>
            <br></br>
          </div>
          :
          ""
      }
      <AutocompleteComponent
        songList={songList}
        setSongList={setSongList}
        setIsLoading={setIsLoading}
      />
      <br></br>
      <br></br>
      <div className="row justify-content-center">
        {
          <SongListSongsDataTableComponent songList={songList} />
        }
      </div>
    </div>
  );
}