import React from 'react';
import { Navigate } from 'react-router-dom';
import { useState } from 'react';
import { labelStyle, inputStyle, formStyle, submitStyle, appStyle } from '../utils/FormUtil'
import { SongListApi } from '../apis/SongListApi'
import LoadingComponent from '../components/LoadingComponent';

const Field = React.forwardRef(({ label, type }, ref) => {
  return (
    <div>
      <label style={labelStyle} >{label}</label>
      <input ref={ref} type={type} style={inputStyle} />
    </div>
  );
});

const Form = ({ onSubmit }) => {
  const songListNameRef = React.useRef();
  const songListDescriptionRef = React.useRef();
  const handleSubmit = e => {
    e.preventDefault();
    const data = {
      songListName: songListNameRef.current.value,
      songListDescription: songListDescriptionRef.current.value
    };
    onSubmit(data);
  };
  return (
    <form style={formStyle} onSubmit={handleSubmit} >
      <Field ref={songListNameRef} label="Name:" type="text" />
      <Field ref={songListDescriptionRef} label="Description:" type="text" />
      <div>
        <button style={submitStyle} type="submit">Create New Song List</button>
      </div>
    </form>
  );
};

export default function CreateNewSongListPage() {
  const [isLoading, setIsLoading] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [path, setPath] = useState(null)

  const handleSubmit = data => {
    setIsLoading(true)
    SongListApi.create(data.songListName, data.songListDescription)
      .then(result => {
        setRedirect(true)
        setPath(`/song-lists/${result.uuid}`)
        setIsLoading(false)
      })
  };

  if (isLoading) {
    return <LoadingComponent/>
  }

  return (
    <div>
      <br></br>
      <div style={appStyle}>
        <Form onSubmit={handleSubmit} />
      </div>
      <div>
        {redirect ? <Navigate to={path} /> : ""}
      </div>
    </div>
  );
}