import axios from 'axios';

const apiUrl = "https://api-taraneem.onrender.com/api/v1/dbx-song-lists"

export const SongListApi = {
  get: async function (songListUuid) {
    try {
      const response = await axios.get(
        `${apiUrl}/${songListUuid}`,
        {
          headers: {
            "Authorization": "Bearer " + localStorage.getItem("token")
          }
        }
      )
      return response.data
    } catch (error) {
      console.log("API ERROR: ", error)
    }
  },

  getAll: async function () {
    try {
      const response = await axios.get(
        apiUrl,
        {
          headers: {
            "Authorization": "Bearer " + localStorage.getItem("token")
          }
        }
      )
      return response.data
    } catch (error) {
      console.log("API ERROR: ", error)
    }
  },

  create: async function (songListName, songListDescription) {
    try {
      const response = await axios.post(
        apiUrl,
        {
          name: songListName,
          description: songListDescription,
          dbxSongDtos: []
        }
        ,
        {
          headers: {
            "Authorization": "Bearer " + localStorage.getItem("token")
          },
        }
      )
      return response.data
    } catch (error) {
      console.log("API ERROR: ", error)
    }
  },

  update: async function (songList) {
    try {
      const response = await axios.put(
        `${apiUrl}/${songList.uuid}`,
        songList,
        {
          headers: {
            "Authorization": "Bearer " + localStorage.getItem("token")
          },
        }
      )
      return response.data
    } catch (error) {
      console.log("API ERROR: ", error)
    }
  }
}