import axios from 'axios';

const apiUrl = "https://api-taraneem.onrender.com/api/v1/dbx-songs"

export const SongApi = {
	getAll: async function () {
		try {
			const response = await axios.get(apiUrl)
			return response.data
		} catch (error) {
			console.log("API ERROR: ", error)
		}
	}
}