import React from 'react';
import { Container, TextField, Autocomplete, createFilterOptions } from "@mui/material";
import { SongListApi } from '../apis/SongListApi';

export default function SearchComponent({ songList, setSongList, setIsLoading }) {
  const defaultFilterOptions = createFilterOptions();

  return (
    <Container >
      <Autocomplete
        disablePortal
        options={JSON.parse(localStorage.getItem("songs"))}
        getOptionLabel={(option) => option.fileName}
        isOptionEqualToValue={(option, value) => option.fileName === value.fileName}
        size="small"
        sx={{ maxWidth: 300, marginLeft: "auto", marginRight: "auto" }}
        renderInput={(params) => <TextField {...params} label="Add Song" />}
        filterOptions={(options, state) => defaultFilterOptions(options, state).slice(0, 5)}
        onChange={(_, value) => {
          setIsLoading(true)
          if (songList.dbxSongDtos === undefined) { songList.dbxSongDtos = [] }
          songList.dbxSongDtos = [...songList.dbxSongDtos, value]
          SongListApi.update(songList).then(result => {
            setSongList(null)
            setSongList(result)
            setIsLoading(false)
          })
        }}
      />
    </Container>
  )
}