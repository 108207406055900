export const SongUtil = {
  getSongIndex: function (songs, songUuid) {
    for (let i = 0; i < songs.length; i++) {
      if (songs[i].uuid === songUuid) {
        return i
      }
    }

    return 0
  },

  updateUrl: function (songs) {
    for (let i = 0; i < songs.length; i++) {
      songs[i].uri = songs[i].uri.replace(
        "https://proxy.cors.sh/https://www.dropbox.com",
        "https://dl.dropboxusercontent.com"
      )
    }
  }
}