import React from 'react';
import { useState, useEffect } from 'react';
import LoadingComponent from '../components/LoadingComponent';
import ViewSongComponent from '../components/ViewSongComponent';
import { useParams } from "react-router-dom";
import { SongListApi } from '../apis/SongListApi'
import { SongUtil } from '../utils/SongUtil'

export default function ViewSongsListSongPage() {
  const [isLoading, setIsLoading] = useState(true)
  const [songList, setSongList] = useState(null)
  const [selectedSong, setSelectedSong] = useState(null)
  const [selectedSongIndex, setSelectedSongIndex] = useState(0)

  const songListUuid = useParams().songListUuid
  const songUuid = useParams().songUuid

  useEffect(() => {
    SongListApi.get(songListUuid).then(result => {
      SongUtil.updateUrl(result.dbxSongDtos)
      var songIndex = 0
      if (songUuid != null) {
        songIndex = SongUtil.getSongIndex(result.dbxSongDtos, songUuid)
      }

      setIsLoading(false)
      setSongList(result)
      setSelectedSong(result.dbxSongDtos[songIndex])
      setSelectedSongIndex(songIndex)
    })
  }, [songListUuid, songUuid])

  if (isLoading) {
    return (
      <LoadingComponent />
    )
  }

  return (
    <ViewSongComponent
      songs={songList.dbxSongDtos}
      selectedSong={selectedSong}
      selectedSongIndex={selectedSongIndex}
      url={`/song-lists/${songList.uuid}/songs`}
      previousPageUrl={`/song-lists/${songList.uuid}`}
    />
  )
}