import React from 'react';
import { Navigate } from 'react-router-dom';
import { useState } from 'react';
import { labelStyle, inputStyle, formStyle, submitStyle, appStyle } from '../utils/FormUtil'
import { AuthApi } from '../apis/AuthApi'
import LoadingComponent from '../components/LoadingComponent';

const Field = React.forwardRef(({ label, type }, ref) => {
  return (
    <div>
      <label style={labelStyle} >{label}</label>
      <input ref={ref} type={type} style={inputStyle} />
    </div>
  );
});

const Form = ({ onSubmit }) => {
  const usernameRef = React.useRef();
  const passwordRef = React.useRef();
  const handleSubmit = e => {
    e.preventDefault();
    const data = {
      username: usernameRef.current.value,
      password: passwordRef.current.value
    };
    onSubmit(data);
  };
  return (
    <form style={formStyle} onSubmit={handleSubmit} >
      <Field ref={usernameRef} label="Username:" type="text" />
      <Field ref={passwordRef} label="Password:" type="password" />
      <div>
        <button style={submitStyle} type="submit">Login</button>
      </div>
    </form>
  );
};

export default function Login() {
  const [isLoading, setIsLoading] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [error, setError] = useState(false)

  const handleSubmit = data => {
    setIsLoading(true)
    AuthApi.login(data.username, data.password)
      .then(result => {
        if (result.message !== undefined) {
          setError(true)
          setIsLoading(false)
        } else {
          localStorage.setItem("token", result.token)
          localStorage.setItem("firstName", result.firstName)
          setRedirect(true)
          setIsLoading(false)
        }
      })
  }

  if (isLoading) {
    return <LoadingComponent />
  }

  return (
    <div>
      <br></br>
      <div style={appStyle}>
        <Form onSubmit={handleSubmit} />
      </div>
      <div>

        <h1 style={{ color: 'red' }}>{error ? "Invalid username or password" : ""}</h1>
        {redirect ? <Navigate to="/song-lists" /> : ""}
      </div>
    </div>
  )
}