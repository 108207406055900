import { useMemo, useState } from 'react';
import {
  MRT_TableContainer,
  useMaterialReactTable,
} from 'material-react-table';
import {
  Box,
  IconButton,
  Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom"
import { SongListApi } from '../apis/SongListApi';
import "../DragDropTouch"

export default function SongListSongsDataTableComponent({ songList }) {
  const navigate = useNavigate()
  const columns = useMemo(
    () => [
      {
        accessorKey: 'fileName',
        header: 'Song Name',
      }
    ],
    [],
  );

  if (songList.dbxSongDtos === undefined) {
    songList.dbxSongDtos = []
  }
  const [data, setData] = useState(songList.dbxSongDtos);

  const table = useMaterialReactTable({
    autoResetPageIndex: false,
    columns,
    data,
    enableRowOrdering: true,
    enableSorting: false,
    enableRowActions: true,
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          data.splice(
            hoveredRow.index,
            0,
            data.splice(draggingRow.index, 1)[0],
          );
          setData([...data]);
          songList.dbxSongDtos = data
          SongListApi.update(songList)
        }
      },
    }),
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={(e) => {
            const updatedData = data.filter((song) => song.uuid !== row.original.uuid)
            setData(updatedData)
            songList.dbxSongDtos = updatedData
            SongListApi.update(songList)
            e.stopPropagation()
          }}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        navigate(`/song-lists/${songList.uuid}/songs/${row.original.uuid}`)
      }
    }),
  });

  return (
    <div>
      {
        // eslint-disable-next-line react/jsx-pascal-case
        <MRT_TableContainer table={table} style={{ width: '90%' }} />
      }
    </div>
  )

}