import React from 'react';
import { Container, Button } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import BackComponent from '../components/BackComponent';
import { Link } from 'react-router-dom';

export default function ViewSongComponent({ songs, selectedSong, selectedSongIndex, url, previousPageUrl }) {
  return (
    <Container>
      <BackComponent url={previousPageUrl} />
      <Box sx={{ width: '95%', margin: '0 auto' }}>
        <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={1}>
            {
              selectedSongIndex === 0 ? ""
                :
                <Link to={`${url}/${songs[selectedSongIndex - 1].uuid}`}>
                  <Button
                    startIcon={<ArrowBackIcon />}
                    sx={{ top: '40%', marginTop: '-25%', display: 'flex', justifyContent: 'left' }}
                  />
                </Link>
            }
          </Grid>
          <Grid item xs={10} sx={{ alignContent: 'center' }}>
            <div style={{ marginRight: '-20%' }}>
              {
                selectedSong.fileType === "png" ? <img src={selectedSong.uri} alt={"song"} width='90%' />
                  :
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <Viewer fileUrl={selectedSong.uri} />
                  </Worker>
              }
            </div>
          </Grid>
          <Grid item xs={1}>
            {
              selectedSongIndex === songs.length - 1 ? ""
                :
                <Link to={`${url}/${songs[selectedSongIndex + 1].uuid}`}>
                <Button
                  startIcon={<ArrowForwardIcon />}
                  sx={{ top: '40%', marginTop: '-25%', marginLeft: '-10%' }}
                />
                </Link>
            }
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}