import React from 'react'
import { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';

import SongsPage from './pages/SongsPage';
import ViewSongPage from './pages/ViewSongPage';
import LoginPage from './pages/LoginPage';
import SongListsPage from './pages/SongListsPage';
import ViewSongListPage from './pages/ViewSongListPage';
import ViewSongListSongPage from './pages/ViewSongListSongPage';
import CreateNewSongListPage from './pages/CreateNewSongListPage';
import LoadingComponent from './components/LoadingComponent';
import NavigationBarComponent from './components/NavigationBarComponent';
import { SongApi } from './apis/SongApi'

import './App.css';

export default function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("songs") == null) {
      SongApi.getAll().then(songs => {
        localStorage.setItem("songs", JSON.stringify(songs))
        setIsLoading(false)
      })
    } else {
      setIsLoading(false)
    }
  }, [])

  if (isLoading) {
    return <LoadingComponent />
  }

  return (
    <Router History={History}>
      <NavigationBarComponent />
      <div className="container-fluid text-center">
        <div className="row justify-content-center">
          <Routes>
            <Route path="/" element={<SongsPage />} />
            <Route path="/songs/:songUuid" element={<ViewSongPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/song-lists" element={<SongListsPage />} />
            <Route path="/song-lists/:songListUuid" element={<ViewSongListPage />} />
            <Route path="/song-lists/:songListUuid/songs" element={<ViewSongListSongPage />} />
            <Route path="/song-lists/:songListUuid/songs/:songUuid" element={<ViewSongListSongPage />} />
            <Route path="/song-lists/create" element={<CreateNewSongListPage />} />
          </Routes>
        </div>
      </div>
    </Router>
  )
}