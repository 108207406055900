import React from 'react';
import { Button } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';

export default function BackComponent({ url }) {
  return (
    <Box display={'flex'} justifyContent={'flex-start'}>
      <Link to={url}>
        <Button startIcon={<ArrowBackIcon />}>
          Back
        </Button>
      </Link>
    </Box>
  )
}