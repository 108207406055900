import React from 'react';
import { Link } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

function SongComponent({ song }) {
  return (
    <ListItem divider={true}>
      <ListItemText
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        primaryTypographyProps={{ fontSize: 20 }}
      >
        <Link to={`/songs/${song.uuid}`}>{song.fileName}</Link>
      </ListItemText>
    </ListItem>
  );
}

export default SongComponent;