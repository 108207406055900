import React from 'react';
import { useState, useEffect } from 'react';
import LoadingComponent from '../components/LoadingComponent';
import SongListsDataTableComponent from '../components/SongListsDataTableComponent';
import { Link } from 'react-router-dom';
import { SongListApi } from '../apis/SongListApi';
import BackComponent from '../components/BackComponent';

export default function SongsListsPage() {

  const [isLoading, setIsLoading] = useState(true)
  const [songLists, setSongLists] = useState([])

  useEffect(() => {
    SongListApi.getAll().then(result => {
      setSongLists(result)
      setIsLoading(false)
    })
  }, [])

  if (isLoading) {
    return (
      <LoadingComponent />
    )
  }

  return (
    <div className="container-fluid text-center">
      <br />
      <BackComponent url={`/`} />
      <br></br>
      Hi {localStorage.getItem("firstName")}!
      <br></br>
      <br></br>
      <Link to={`/song-lists/create`}>Create New Song List</Link>
      <br></br>
      <br></br>
      <div className="row justify-content-center">
        {
          <SongListsDataTableComponent songLists={
            songLists.map((val) => {
              return val
            })
          } />
        }
      </div>
    </div>
  );
}