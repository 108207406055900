import React from 'react';
import { useState } from 'react';
import SongComponent from '../components/SongComponent';
import SearchComponent from '../components/SearchComponent';
import { Container } from "@mui/material";

export default function SongsPage() {
  const [searchTerm, setSearchTerm] = useState("")

  return (
    <div>
      <br />
      <SearchComponent setSearchTerm={setSearchTerm}/>
      <Container maxWidth="xs">
        {
          JSON.parse(localStorage.getItem("songs")).filter((song) => {
            return searchTerm === "" ||
              song.fileName.toLowerCase().includes(searchTerm.toLowerCase())
          }).map((val, key) => {
            if (key < 30) {
              return <SongComponent song={val} key={key} />
            } else {
              return null
            }
          })
        }
      </Container>
    </div>
  );
}